import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import Web3 from "web3/lib";
import vClickOutside from 'v-click-outside'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import './quasar'
import "./assets/css/plugins/bootstrap.min.css";
import "./assets/css/plugins/slick.css";
import "./assets/css/plugins/animate.css";
import "./assets/css/style.css";

import router from "./router";

Vue.config.productionTip = false;

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.use(vClickOutside)

new Vue({
  store,
  beforeCreate() {
    // const httpProvider = new Web3.providers
    //              .HttpProvider("https://mainnet.infura.io/v3/27e3807ed9ad495f962c2e0ccb2d8faa");

    // const httpProvider = new Web3.providers
    //              .HttpProvider("https://goerli.infura.io/v3/27e3807ed9ad495f962c2e0ccb2d8faa");

    const httpProvider = new Web3.providers
                 .HttpProvider("https://matic-mumbai.chainstacklabs.com");

    window.provider = httpProvider;
    window.web3 = new Web3(httpProvider);

    store.commit('init')
    store.commit('read_parameters')
    store.commit('read_dce')

    setInterval(()=>{
      store.commit('read_dce')
    }, 10000)
  },

  router,
  render: (h) => h(App),
}).$mount("#app");
