import Vuex from "vuex";
import Vue from "vue";
import { BigNumber } from 'bignumber.js'
import {whiteList} from '../constants/whitelist.js'
import keccak256 from 'keccak256'
import { MerkleTree } from 'merkletreejs'
import Multicall from '@dopex-io/web3-multicall';
import axios from 'axios'

import abiDce from '@/abi/dce.json'

import { Alchemy, Network } from 'alchemy-sdk'

BigNumber.config({ EXPONENTIAL_AT: 100 })

// const ADDR_MULTICALL = '0x55137931d78e9A237aD461c829d9C5DE3695808B'    // Ethereum mainnet
// const ADDR_MULTICALL = '0xB8F163fBcAAB510B652f4aB735CD512149Ea1478'    // Ethereum Goerli testnet
const ADDR_MULTICALL = '0xa751154933350D4d499B2465c67690387655DbB6'       // Polygon Mumbai

const ADDR_TOKEN_DCE = '0x71C22bC36b4eb081a3bDe3c966B1241aB320C7fE'

const MINT_END_DATE = 1680384000

const alchemyConfig = {
  apiKey: "xSJHiL2yyKe6iuH6Kv3HS_1kL-orWlt-",   // Key on Alchemy.com
  network: Network.MATIC_MUMBAI,
  // network: Network.ETH_MAINNET,
  // network: Network.ETH_GOERLI,
};

const alchemy = new Alchemy(alchemyConfig);

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    account: null,
    messageContent: null,
    messageType: null,
    ethPrice: Number,
    contracts: {
      multiCall: null,
      tokenDce: null
    },
    dce: {
      isOwner: false,
      enableMint: false,
      totalSupply: 0,
      priceInPresale: BigNumber(0),
      priceInPubsale: BigNumber(0),
      status: null,
      myPresaleMintedCount: 0,
      max_TokenCountPerPresale: 0,
      max_TokenCountPerPubsale: 0,
      max_TokenCountForFreesale: 0,
      max_BatchMintSize: 0,
      max_TokenCount: 0,
      max_TokenCountForPresale: 0,
      total_owners: 0,
      end_date: MINT_END_DATE
    },
  },
  mutations: {
    async init(state) {
      state.contracts.multiCall = new Multicall({
        multicallAddress: ADDR_MULTICALL,
        provider: window.provider,
      });
      
      state.contracts.tokenDce = new window.web3.eth.Contract(abiDce, ADDR_TOKEN_DCE);
    },
    set_account(state,account) {
      state.account = account
    },
    show_info(state,message) {
      state.messageContent = message
      state.messageType = 'info'
    },
    show_success(state,message) {
      state.messageContent = message
      state.messageType = 'success'
    },
    show_error(state,message) {
      state.messageContent = message
      state.messageType = 'error'
    },
    show_warning(state,message) {
      state.messageContent = message
      state.messageType = 'warning'
    },
    async read_parameters(state) {
      // Get global information
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenDce.methods.MAX_DCE_COUNT(),
        state.contracts.tokenDce.methods.maxLimitPerPresale(),
        state.contracts.tokenDce.methods.maxLimitPerPubsale(),
        state.contracts.tokenDce.methods.maxBatchSize(),
        state.contracts.tokenDce.methods.maxLimitForPresale(),
      ]);

      state.dce.max_TokenCount = result[0];
      state.dce.max_TokenCountPerPresale = result[1];
      state.dce.max_TokenCountPerPubsale = result[2];
      state.dce.max_BatchMintSize = result[3];
      state.dce.max_TokenCountForPresale = result[4];

      const params = {
        'vs_currency': 'usd',
        'localization': false,
        'sparkline': false
      };

      var coingecko

      axios.get('https://api.coingecko.com/api/v3/coins/ethereum', {params})
      .then(response => ( 
        coingecko = response.data))
      .catch(error => console.log(error))
      .finally(() => state.ethPrice = coingecko.market_data.current_price.usd)
    },
    async read_dce(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenDce.methods.totalSupply(),
        state.contracts.tokenDce.methods.getStatus(),
        state.contracts.tokenDce.methods.presalePrice(),
        state.contracts.tokenDce.methods.pubsalePrice(),
        state.contracts.tokenDce.methods.enableMint()
      ]);

      state.dce.totalSupply = BigNumber(result[0]);
      state.dce.status = result[1];
      state.dce.priceInPresale = BigNumber(result[2]);
      state.dce.priceInPubsale = BigNumber(result[3]);
      state.dce.enableMint = result[4];

      if (state.account) {
        result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenDce.methods.presaleMinted(state.account.address),
          state.contracts.tokenDce.methods.buyerInfo(state.account.address),
        ]);

        state.dce.myPresaleMintedCount = Number(result[0]);
        state.dce.max_TokenCountForFreesale = Number(result[1]);
      }

      // Get count of token owners from alchemy.com
      const holders = await alchemy.nft.getOwnersForContract(ADDR_TOKEN_DCE);
      state.dce.total_owners = holders.owners.length;
    }
  },
  method: {
  },
  actions: {
    showMessage: ({ commit }, data) => {
      commit(data.kind, data.content);
    },
    connect({commit}, params) {
      if (params.address == null && params.provider == null) {
        // commit('show_error', 'Please switch to the Ethereum Network!');
        return;
      }
      const account = {
        address: params.address,
      }

      commit('init')
      commit('set_account', account)
      commit('read_parameters')
      commit('read_dce')
    },
    disconnect({state, commit}) {
      state.account = null;
      commit('read_dce')
    },
    async mint({state,commit}, params) {
      if (state.account == null) {
        commit('show_error', 'Please Connect Wallet!');
        return;         
      }

      if (state.dce.totalSupply == state.dce.max_TokenCount) {
        commit('show_error', 'Mint Ended.');
        return;  
      }

      if (!state.dce.enableMint) {
        commit('show_error', 'Mint is disabled now.');
        return;  
      }

      if (Number(params.countOfToken) <= 0) {
        commit('show_error', 'Wrong mint count.');
        return;
      }

      if (Number(params.countOfToken) > state.dce.max_BatchMintSize) {
        commit('show_error', 'Maximum Batch Mint size is 10.');
        return;
      }

      if (Number(state.dce.totalSupply) + Number(params.countOfToken) > state.dce.max_TokenCount) {
        commit('show_error', 'Too many mint count.');
        return;
      }

      if (state.dce.status == 0) {          // pending status
        commit('show_error', 'Mint Not Started yet.');
        return;
      }
      else if (state.dce.status == 1) {     // whitelist sale
        // free sale
        if (params.freeNint) {
          state.contracts.tokenDce.methods.buyerInfo(state.account.address).call().then((ret)=>{
            if (Number(ret) < Number(params.countOfToken)) {
              commit('show_error', 'Too Many Free mint Tokens At Your Wallet.');
              return;
            }
  
            state.contracts.tokenDce.methods.freeMint(params.countOfToken).send({
              from: state.account.address
            }).then(()=>{
              commit('show_success', 'Free Minted NFT Successfully!');
              commit('read_dce');
            })
          })
          
          return;
        }
        const leaves = whiteList.map((addr) => keccak256(addr));

        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        const root = tree.getRoot().toString("hex");
  
        console.log("root_hexroot : ", tree.getHexRoot())
  
        const leaf = keccak256(state.account.address);
        let proof = tree.getHexProof(leaf);

        if (!tree.verify(proof, leaf, root)) {
          commit('show_error', 'Not Whitelist Account.');
          return;
        }

        state.contracts.tokenDce.methods.presaleMinted(state.account.address).call().then((ret)=>{
          if (Number(ret) + Number(params.countOfToken) > state.dce.max_TokenCountPerPresale) {
            commit('show_error', 'Too Many Presale Tokens At Your Wallet.');
            return;
          }

          state.contracts.tokenDce.methods.presaleMint(params.countOfToken, proof).send({
            from: state.account.address,
            value:BigNumber(state.dce.priceInPresale).times(params.countOfToken).integerValue().toString()
          }).then(()=>{
            commit('show_success', 'Minted NFT Successfully!');
            commit('read_dce');
          })
        })
      } 
      else {    // public sale

        state.contracts.tokenDce.methods.pubsaleMinted(state.account.address).call().then((ret)=>{
          if (Number(ret) + Number(params.countOfToken) > state.dce.max_TokenCountPerPubsale) {
            commit('show_error', 'Too Many Public sale Tokens At Your Wallet. Max limit is 10 NFTs.');
            return;
          }

          state.contracts.tokenDce.methods.pubsaleMint(params.countOfToken).send({
            from: state.account.address,
            value:BigNumber(state.dce.priceInPubsale).times(params.countOfToken).integerValue().toString()
          }).then(()=>{
            commit('show_success', 'Minted NFT Successfully!');
            commit('read_dce');
          })
        })
      }
    },
  }
})
